import { getShopProductLink } from "_common/utils/shop";

import Presentation from "./presentation";

const component = props => {

	const productURL = getShopProductLink ( { productId: props?.data?.slug, addUTMParams: true } );

	return (
		<Presentation
			data       = { props?.data }
			isLoading  = { props?.isLoading }
			productURL = { productURL }
		/>
	);
};

export default component;

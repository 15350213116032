import _error          from "_common/error";
import { getUserData } from "_common/utils/user";
import XHR             from "_common/xhr";
import { configs }     from "_config/index";

import {
	_startLiveBroadcast,
	addModeratorMutation,
	banUserForBroadcaster,
	broadcastSessionMutation,
	createIVSPoll,
	deleteCommentMutation,
	deleteLive,
	donationGoalMutation,
	downloadStream,
	endBroadcastSession,
	getModeratorQuery,
	giveawayCoins,
	pinComment,
	purchaseOverlay,
	purchaseStreamingTool,
	purchaseThumbnail,
	removeModeratorMutation,
	reportBroadcastSession,
	setOnboardingFavoriteGames,
	setRtmpStreamAdditionalInfo,
	streamFeedbackMutation,
	thankForDonationMutation,
	unmuteUserMutation,
	updateIVSPoll
} from "./mutations";
import {
	GetBroadcastSession,
	GetBroadcastSessions_Q1,
	GetBroadcasterDetails_Q1,
	GetCarouselSessions,
	GetContentLocales,
	GetGamingBroadcastSessions_Q1,
	GetStreamDownloadStatus,
	GetTrendingBroadcastSessions_Q4,
	GetTrendingBroadcastSessions_Q5,
	GetTrendingBroadcastSessions_Q6,
	GetTrendingBroadcastSessions_Q7,
	GetTrendingBroadcastSessions_Q8,
	SearchBroadcastSessions,
	TopDonorLeaderboardQuery,
	customOverlays,
	customThumbnails,
	fetchPreviousComments,
	getAllIVSPollForBroadcast,
	getBroadcastSessionQuery,
	getConcurrentViewersPerTimeLapsedById,
	getFanLeaderboard_Q1,
	getFollowersLive,
	getMutedUsersQuery,
	getNginxRtmpUrls,
	getOnboardingGames,
	getPollsConfig,
	getPopularGames,
	getPopularGamesWithoutSessions,
	getTagsByGenre,
	getUserBroadcasts_Q1,
	getUserBroadcasts_Q2,
	getUserMonetizationStatus,
	liveTabData,
	overlayTypes,
	personalizedLiveData,
	personalizedLiveDataBroadcastIds,
	popularStreamingGames,
	popularStreamingGamesName,
	purchaseStatusStreamingTool,
	streamTools,
	tournamentData,
	tournamentDataWithoutSessions,
	updateMonetizationPopup,
	voteOnIvsPollByUser
} from "./queries";

export const getBroadcastSessionsByUserId = async ( {
	pageNo = 1,
	pageSize = 10
} ) => {
	const userData = getUserData ();
	const response = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query: GetBroadcastSessions_Q1 ( {
				userId: userData.userId,
				pageNo,
				pageSize
			} ),
			variables: { sportsFanId: userData.userId, pageNo, pageSize }
		}
	} );

	if ( !response.data || !response.data.broadcastSessionsBySportsFanId )
		throw _error ( "Couldn't fetch broadcast session" );

	return response.data.broadcastSessionsBySportsFanId;
};

export const getBroadcastSession = async function ( broadcastId, countryCode ) {
	if ( !broadcastId ) return;

	const response = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : GetBroadcastSession (),
			variables : { sessionId: parseInt ( broadcastId ) }
		},
		headers: {
			"country-code": countryCode || ""
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	if ( !response.data || !response.data.broadcastSession )
		throw _error ( "Couldn't fetch broadcast session" );

	return response.data.broadcastSession;
};

export const getPollsConfigData = async () => {
	const response = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: getPollsConfig () }
	} );

	return response?.data?.pollsConfigure;
};

export const getBroadcastSessionByIds = async function ( broadcastIds ) {
	if ( !broadcastIds && !broadcastIds.length ) return;

	const response = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : { query: SearchBroadcastSessions ( broadcastIds ) }
	} );

	if ( !response.data || !response.data.broadcastSessionByIds )
		throw _error ( "Couldn't fetch broadcast sessions" );

	return response?.data?.broadcastSessionByIds;
};

export const getRecommendedList = async () => {
	const response = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : personalizedLiveData (),
			variables : { pageNo: 1, pageSize: 10 }
		}
	} );

	if ( !response.data ) throw _error ( "Couldn't fetch recommneded list" );

	return response.data;
};

export const getLiveTabOrder = async function () {
	const response = await XHR.post.bind ( this ) ( {
		url             : configs.BROADCAST_URL,
		data            : { query: liveTabData (), variables: { pageNo: 1, pageSize: 10 } },
		backupApiConfig : { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	if ( !response.data ) throw _error ( "Couldn't fetch live tab order" );

	return response.data;
};

export const getTournaments = async function ( idArray, size = 10, pageNo = 1 ) {
	const response = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : tournamentData (),
			variables : { pageNo: pageNo, pageSize: size, tournamentIds: idArray }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	if ( !response.data ) throw _error ( "Couldn't fetch tournaments" );

	return response.data;
};

export const getTournamentsWithoutSessions = async function (
	idArray,
	size = 10
) {
	const response = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : tournamentDataWithoutSessions (),
			variables : { pageNo: 1, pageSize: size, tournamentIds: idArray }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	if ( !response.data ) throw _error ( "Couldn't fetch tournaments" );

	return response.data;
};

export const getRecommendedListBroadcastIds = async () => {
	const response = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : personalizedLiveDataBroadcastIds (),
			variables : { pageNo: 1, pageSize: 10 }
		}
	} );

	if ( !response.data )
		throw _error ( "Couldn't fetch recommneded list broadcast ids" );

	return response.data;
};

export const getFanLeaderboard = async function ( params ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : { query: getFanLeaderboard_Q1 ( params ) }
	} );

	return result?.data?.fanLeaderBoard;
};

export const getUserSessions = async function ( params ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : { query: getUserBroadcasts_Q1 ( params ) }
	} );

	return result?.data?.broadcastSessionsBySportsFanId;
};

export const getBroadcasterDetails = async function ( { userId } ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : { query: GetBroadcasterDetails_Q1 ( { userId } ) }
	} );

	return result?.data?.broadcaster;
};

export const getTrendingBroadcastSessions = async ( {
	pageNo = 1,
	pageSize = 10,
	mergedBroadcasts = 1,
	gamingSessions = 0,
	isLive = 1,
	androidPackageName = null
} ) => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : GetTrendingBroadcastSessions_Q4 (),
			variables : {
				pageNo,
				pageSize,
				mergedBroadcasts,
				gamingSessions,
				isLive,
				androidPackageName
			}
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	return result?.data?.trendingBroadcastSessions || [];
};

export const getGamingBroadcastSessions = async params => {
	const result = await XHR.post ( {
		url     : configs.BROADCAST_URL,
		timeout : 15000,
		data    : { query: GetGamingBroadcastSessions_Q1 ( params ) }
	} );

	return result?.data?.gamingBroadcastSessions.gamingSessions;
};

export const getAllBroadcastsByType = async function ( {
	pageNo = 1,
	pageSize = 10,
	mergedBroadcasts = 1,
	gamingSessions = 0,
	isLive = 1,
	androidPackageName = null,
	isTopic = 0
} ) {
	const result = await XHR.post.bind ( this ) ( {
		url     : configs.BROADCAST_URL,
		timeout : 15000,
		data    : {
			query     : GetTrendingBroadcastSessions_Q5 (),
			variables : {
				pageNo,
				pageSize,
				mergedBroadcasts,
				gamingSessions,
				isLive,
				androidPackageName,
				isTopic
			}
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	return result?.data?.trendingBroadcastSessions || [];
};

export const getAllBroadcastsByPackageName = async function ( params ) {
	const result = await XHR.post.bind ( this ) ( {
		url     : configs.BROADCAST_URL,
		timeout : 15000,
		data    : {
			query     : GetTrendingBroadcastSessions_Q8 (),
			variables : params
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	return result?.data?.trendingBroadcastSessions || [];
};

export const getAllTrendingSessions = async ( {
	pageNo = 1,
	pageSize = 10,
	mergedBroadcasts = 1,
	isLive = 1
} ) => {
	const response = await XHR.post ( {
		url     : configs.BROADCAST_URL,
		timeout : 15000,
		data    : {
			query     : GetTrendingBroadcastSessions_Q6 (),
			variables : { pageNo, pageSize, mergedBroadcasts, isLive }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	if ( !response.data || !response.data.trendingBroadcastSessions ) return [];

	return response.data.trendingBroadcastSessions;
};

export const getUserExistingSessions = async params => {
	const result = await XHR.post ( {
		url     : configs.BROADCAST_URL,
		timeout : 5000,
		data    : { query: getUserBroadcasts_Q2 ( params ) }
	} );

	return result?.data?.broadcastSessionsBySportsFanId;
};

export const getRTMPStream = async function ( params ) {
	const { resetStreamKey = false, sessionType = null } = params || {};

	const result = await XHR.post.bind ( this ) ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: getNginxRtmpUrls ( { resetStreamKey, sessionType } ) }
	} );

	return result?.data?.getNginxRtmpUrls;
};

export const getPopularStreamingGames = async function ( params ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : { query: popularStreamingGames ( params ) }
	} );

	return result?.data?.popularStreamingGames || [];
};

export const getPopularStreamingGameName = async function ( params ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : { query: popularStreamingGamesName ( params ), variables: params }
	} );

	return result?.data?.popularStreamingGames || [];
};

export const startLiveBroadcast = async params => {
	const userData = getUserData ();
	const response = await XHR.post ( {
		url       : configs.BROADCAST_URL,
		transform : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: _startLiveBroadcast ( params, userData ) }
	} );

	if ( !response || !response.data || !response.data.startLiveBroadcast )
		throw _error ( "No data returned in response to start live broadcast" );

	return response.data.startLiveBroadcast;
};

export const endLiveBroadcast = async ( { broadcastSessionId } ) => {
	const res = await XHR.post ( {
		url       : configs.BROADCAST_URL,
		transform : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: endBroadcastSession ( { broadcastSessionId } ) }
	} );

	return res.data.endBroadcastSession;
};

export const reportSession = async params => {
	await XHR.post ( {
		url       : configs.BROADCAST_URL,
		transform : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: reportBroadcastSession ( params ) }
	} );

	return "success";
};

export const verifySessionStatus = async function ( { cdnUrl, userId } ) {
	const response = await XHR.post.bind ( this ) ( {
		url  : `${ configs.BROADCAST_DNS }verifySessionStatus`,
		data : { sportsFanId: userId, pageNo: 1, pageSize: 30, cdnUrl }
	} );

	return response;
};

export const getPopularGameCards = async function ( gameCardIds ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : getPopularGames (),
			variables : { pageNo: 1, pageSize: 10, gameCardIds: gameCardIds }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	return result?.data?.popularGameCards;
};

export const getGameCardApiWithoutSessions = async function ( gameCardIds ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : getPopularGamesWithoutSessions (),
			variables : { pageNo: 1, pageSize: 10, gameCardIds: gameCardIds }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	return result?.data?.popularGameCards;
};

export const getCustomThumbnails = async params => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: customThumbnails ( params ) }
	} );

	return result?.data?.customThumbnails;
};

export const purchaseCustomThumbnails = async params => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: purchaseThumbnail ( params ) }
	} );

	return result?.data?.purchaseThumbnail;
};

export const getStreamTools = async () => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: streamTools () }
	} );

	return result?.data?.streamingTools;
};

export const purchaseStreamTools = async params => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: purchaseStreamingTool ( params ) }
	} );

	return result?.data?.purchaseStreamingTool;
};

export const activateFanRankLeaderboard = async params => {
	const response = await XHR.post ( {
		url       : configs.BROADCAST_URL,
		data      : { query: giveawayCoins ( params ) },
		transform : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		]
	} );

	if ( !response || !response.data || !response.data.giveAwayCoins )
		throw _error ( "Couldn't activate fan rank leaderboard" );

	return response.data.giveawayCoins;
};

export const getPurchaseToolItemStatus = async params => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: purchaseStatusStreamingTool ( params ) }
	} );

	return result?.data?.purchaseStatusStreamingTool;
};

export const addAdditionalRtmpInfo = async params => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: setRtmpStreamAdditionalInfo ( params ) }
	} );

	return result?.data?.setRtmpStreamAdditionalInfo;
};

export const getCustomOverlays = async params => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: customOverlays ( params ) }
	} );

	return result?.data?.customOverlays;
};

export const getLiveFollowers = async () => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: getFollowersLive () }
	} );

	return result?.data;
};

export const getOverlayTypes = async params => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: overlayTypes ( params ) }
	} );

	return result?.data?.overlayTypes;
};

export const purchaseCustomOverlays = async params => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: purchaseOverlay ( params ) }
	} );

	return result?.data?.purchaseOverlay;
};

export const getCarouselSessions = async function ( {
	pageNo = 1,
	pageSize = 10
} ) {
	const response = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : GetCarouselSessions (),
			variables : { pageNo, pageSize }
		}
	} );

	return response?.data?.carouselSessions || [];
};

export const checkIsPWFSession = async broadcastId => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : GetTrendingBroadcastSessions_Q7 (),
			variables : { sessionId: broadcastId }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	return result?.data?.broadcastSession?.playWithFriends;
};

export const muteUserFromSession = async params => {
	const { userId, time, streamerId, isModerator } = params;
	let streamerUserId                              = isModerator ? parseInt ( streamerId, 10 ) : null;

	const res = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: {
			query     : banUserForBroadcaster ( params ),
			variables : {
				userId,
				userSportsFanId : userId,
				minutes         : time,
				streamerUserId
			}
		}
	} );

	return res;
};

export const pinCommentSession = async params => {
	const response = await XHR.post ( {
		url       : configs.BROADCAST_URL,
		transform : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: {
			query: pinComment ( params )
		}
	} );

	return response;
};

export const getHLSStreamManifest = async url => {
	const response = await XHR.get ( { url }, { noHeaders: true, skipInterceptor: true } );

	return response;
};

export const checkIfStreamIsLive = async url => {
	const response = await XHR.head ( { url }, { noHeaders: true } );

	return response;
};

export const addModeratorAPI = async ( { userIds } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : addModeratorMutation (),
			variables : { moderatorUserIds: userIds }
		}
	} );

	return result?.data;
};

export const removeModeratorAPI = async ( { userIds } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : removeModeratorMutation (),
			variables : { moderatorUserIds: userIds }
		}
	} );

	return result?.data;
};

export const getModeratorsList = async ( { pageNo, pageSize } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : getModeratorQuery (),
			variables : { pageNo: parseInt ( pageNo ), pageSize: parseInt ( pageSize ) }
		}
	} );

	return result?.data?.getModerators || [];
};

export const deleteCommentAPI = async ( { broadcastSessionId, commentId } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: { query: deleteCommentMutation ( { broadcastSessionId, commentId } ) }
	} );

	return result?.data;
};

export const TopDonorLeaderboardAPI = async ( {
	broadcastSessionId,
	pageNo,
	pageSize
} ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : TopDonorLeaderboardQuery (),
			variables : {
				broadcastSessionId: parseInt ( broadcastSessionId ),
				pageNo,
				pageSize
			}
		}
	} );

	return (
		result?.data?.streamDonorsLeaderboard || {
			leaderboard   : [],
			totalDonation : 0
		}
	);
};

export const getLanguages = async () => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: GetContentLocales () }
	} );

	return result?.data?.contentLocales;
};

export const getPopularOnboardingGames = async function ( pageSize ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : { query: getOnboardingGames ( pageSize ), variables: { pageSize: 100 } }
	} );

	return result?.data?.games;
};

export const setFavoriteGames = async params => {
	const response = await XHR.post ( {
		url       : configs.BROADCAST_URL,
		data      : { query: setOnboardingFavoriteGames ( params ) },
		transform : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		]
	} );

	if ( !response || !response.data || !response.data.val ) {
		throw _error ( "Couldn't set favorite games" );
	}

	return response?.data;
};

export const unmuteUserAPI = async ( { userId } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : unmuteUserMutation (),
			variables : { userId: parseInt ( userId ) }
		}
	} );

	return result?.data;
};

export const getMutedUsersListAPI = async ( { pageNo, pageSize } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: { query: getMutedUsersQuery ( { pageNo, pageSize } ) }
	} );

	const mutedUsersList =
    result?.data?.getBannedUsers?.paginatedBannedUsersList || [];
	const totalCount     = result?.data?.getBannedUsers?.totalCount || 0;

	return { mutedUsersList, totalCount };
};

export const broadcastSessionMutationAPI = async ( {
	sessionId,
	followerOnlyChat,
	donorOnlyChat,
	thumbnailUrl,
	title,
	description,
	type
} ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : broadcastSessionMutation ( type ),
			variables : {
				sessionId    : parseInt ( sessionId ),
				followerOnlyChat,
				donorOnlyChat,
				thumbnailUrl : thumbnailUrl || "",
				title        : title || "",
				description  : description || ""
			}
		}
	} );

	return result?.data?.updateSessionConfig;
};

export const streamFeedbackAPI = async ( { sessionId, streamRating } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : streamFeedbackMutation (),
			variables : { broadcastSessionId: sessionId, streamRating }
		}
	} );

	return result?.data?.streamFeedback;
};

export const getBroadcastSessionAPI = async ( { broadcastSessionId } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: {
			query     : getBroadcastSessionQuery (),
			variables : { sessionId: parseInt ( broadcastSessionId ) }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_BROADCAST_URL }
	} );

	return result?.data?.broadcastSession;
};

export const getStreamDownloadStatus = async ( { broadcastSessionId } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: { query: GetStreamDownloadStatus ( { broadcastSessionId } ) }
	} );

	return result?.data?.getStreamDownloadStatus;
};

export const getConcurrentViewersAPI = async ( { broadcastSessionId } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query: getConcurrentViewersPerTimeLapsedById ( { broadcastSessionId } )
		}
	} );

	return result?.data?.concurrentViewersPerTimeLapsedById;
};

export const donationGoalMutationAPI = async ( { sessionId, donationGoal } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : donationGoalMutation (),
			variables : {
				broadcastSessionId : parseInt ( sessionId ),
				donationGoal       : parseInt ( donationGoal )
			}
		}
	} );

	return result?.data?.setDonationGoal;
};

export const thankForDonationMutationAPI = async ( { sessionId, commentId } ) => {
	const result = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],

		data: {
			query     : thankForDonationMutation (),
			variables : {
				broadcastSessionId : parseInt ( sessionId ),
				commentId          : parseInt ( commentId )
			}
		}
	} );

	return result?.data?.thankForDonation;
};

export const deleteLivePost = async sessionId => {
	const res = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		timeout           : 5000,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: deleteLive ( sessionId ) }
	} );

	return res?.data;
};

export const getPollsData = async broadcastId => {
	const response = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : getAllIVSPollForBroadcast (),
			variables : { sessionId: parseInt ( broadcastId ) }
		}
	} );

	return response?.data?.getAllIVSPollForBroadcast;
};

export const createPoll = async params => {
	const { sessionId, title, options } = params;
	const response                      = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : createIVSPoll (),
			variables : { sessionId, title, options }
		},
		transform: [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		]
	} );

	return response?.data?.createIVSPoll;
};

export const voteOnPoll = async params => {
	const { sessionId, pollId, optionId } = params;
	const response                        = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : voteOnIvsPollByUser (),
			variables : { sessionId, pollId, optionId }
		},
		transform: [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		]
	} );

	return response?.data?.voteOnIvsPollByUser;
};

export const updatePoll = async params => {
	const { sessionId, pollId, deleted, isActive } = params;
	const response                                 = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : updateIVSPoll (),
			variables : { sessionId, pollId, deleted, isActive }
		},
		transform: [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		]
	} );

	return response?.data?.updateIVSPoll;
};

export const getStudioTags = async ( { irlTag, broadcastTag } ) => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: getTagsByGenre ( { irlTag, broadcastTag } ) }
	} );

	return result?.data?.getTagsByGenre;
};

export const downloadStreamRequest = async ( { broadcastSessionId } ) => {
	const res = await XHR.post ( {
		url               : configs.BROADCAST_URL,
		timeout           : 5000,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: downloadStream ( { broadcastSessionId } ) }
	} );

	return res?.data;
};

export const getLatestSessionUpdates = async function () {
	const response = await XHR.get.bind ( this ) ( {
		url: configs.BROADCAST_DNS + "latestSessionUpdates"
	} );

	return response;
};

export const getBroadcastMonetisationStatus = async () => {
	const result = await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: getUserMonetizationStatus () }
	} );

	return result?.data;
};

export const setMonetizationPopup = async () => {
	await XHR.post ( {
		url  : configs.BROADCAST_URL,
		data : { query: updateMonetizationPopup () }
	} );

	return;
};

export const getPreviousCommentsInLiveStream = async function ( {
	broadcastSessionId,
	timestamp,
	pageSize = 100
} ) {
	if ( !broadcastSessionId ) return [];

	if ( !timestamp ) timestamp = Date.now ();

	const response = await XHR.post.bind ( this ) ( {
		url  : configs.BROADCAST_URL,
		data : {
			query     : fetchPreviousComments (),
			variables : {
				broadcastSessionId : parseInt ( broadcastSessionId ),
				timestamp          : timestamp.toString (),
				pageSize
			}
		}
	} );

	return response?.data?.getPreviousMessagesInSession?.comments || [];
};

export const getStreamSettings = async function () {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.BROADCAST_DNS }getStreamSettings`
	} );

	return response;
};

export const setStreamSettings = async function ( data ) {
	const response = await XHR.post.bind ( this ) ( {
		url  : `${ configs.BROADCAST_DNS }setStreamSettings`,
		data : data
	} );

	return response;
};

import dynamic            from "next/dynamic";
import { isValidElement } from "react";
import useSWR             from "swr";

import { getAllCategories, getProductsByCategory } from "_services/api/shop";

const Desktop = dynamic ( () => import ( "./desktop" ) );
const Mobile  = dynamic ( () => import ( "./mobile" ) );

const fetcher = async () => {
	const categories        = await getAllCategories ();
	const categoriesToFetch = categories.map ( v => v.name );

	const res = await getProductsByCategory ( {
		categoryNames : categoriesToFetch,
		pageSize      : 10,
		pageNo        : 1
	} );

	return res.items.slice ( 0, 6 );
};

const HomePage = props => {
	const { data, isValidating } = useSWR (
		"shop-products",
		fetcher,
		{
			revalidateIfStale     : false,
			revalidateOnFocus     : false,
			revalidateOnReconnect : false
		}
	);

	if ( props?.isMobile )
		return (
			<Mobile
				{ ...props }
				loading  = { isValidElement }
				shopData = { data }
			/>
		);

	return (
		<Desktop
			loading  = { isValidating }
			shopData = { data }
			{ ...props }
		/>
	);

};

export default HomePage;

import { createUrl }   from "_common/utils/seo";
import { ShopBaseURL } from "_config/index";

export const getShopProductLink = ( { productId, addUTMParams = false } ) => {
	const productURL = `${ ShopBaseURL }/product/${ productId }`;
	const _url       = new URL ( productURL );

	if ( addUTMParams )
		return createUrl ( _url, { source: "home_page", medium: "web", campaign: productId } );

	return createUrl ( _url );
};

export const getShopLink = overrideParams => {
	const _url = new URL ( ShopBaseURL );

	const _params = overrideParams || { source: "side_nav", medium: "web", campaign: "organic" };

	Object.entries ( _params ).forEach ( ( [ key, value ] ) => {
		_url.searchParams.append ( "utm_" + key, value );
	} );

	return _url.href;
};

export const getProductPrice = data => {
	const isQwikCilver     = provider => provider?.toLowerCase () === "qwikcilver";
	let defaultVar         = data?.variation?.[0].values.find ( v => v.id.toString () === data?.defaultVarId?.toString () );
	const isQwikCilverItem = isQwikCilver ( data?.provider );

	if ( !defaultVar ) {
		defaultVar = data?.variation?.[0]?.values?.[0];
	}

	const qwikCilverPrice = +defaultVar?.price?.min <= 50 ? 50 : defaultVar?.price?.min || 0;

	const discount  = +data?.variation?.[0].values[0].discountPer;
	const costPrice =  isQwikCilverItem ? qwikCilverPrice : data?.variation?.[0].values[0].costPrice;
	const sellPrice = +costPrice - ( +costPrice * discount / 100 );

	return {
		costPrice,
		sellPrice,
		discount,
		isQwikCilverItem
	};
};

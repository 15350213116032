import axios from "axios";

import _error                                                     from "_common/error";
import logger                                                     from "_common/logger";
import { getAppVersion, getDeviceId, getDeviceType, getUserData } from "_common/utils/user";

import { checkFallbackURLEnabled, checkIsBrowser, enableBackupAPIsInCookies } from "./utils";
import { handleForcedLogout, refreshTokenLogic }                              from "./utils/authInterceptor";

const log = logger ( { file: "common/xhr" } );

let called = false;

const axiosInterceptor = () => {
	called = true;

	axios.interceptors.request.use (
		config => {
			const fallbackURL = config?.backupApiConfig?.fallbackBaseURL;

			if ( config?.isFallbackURLEnabled && fallbackURL ) {
				config.url = fallbackURL;
			}

			return config;
		},
		error => {
			return Promise.reject ( error );
		}
	);

	axios.interceptors.response.use (
		response => {
			return response;
		},
		error => {
			const isBrowser       = checkIsBrowser ();
			const newError        = _error ( error );
			const originalRequest = error.config;

			if ( originalRequest.skipInterceptor )
				return Promise.reject ( error );

			if ( error?.response && ( error?.response?.status === 401 || error?.response?.status === 403 ) ) {

				// Refresh token logic
				if ( error.response.status === 403 ) {

					if ( !isBrowser )
						throw new Error ( "TOKEN_EXPIRED_FORCE_LOGOUT" );

					return handleForcedLogout ();
				}

				if ( error.response.status === 401 && !originalRequest._retry ) {
					originalRequest._retry = true;

					try {
						return refreshTokenLogic ( originalRequest );
					} catch ( e ) {
						return;
					}
				}
			} else if ( newError?.code >= 500 && error?.config?.backupApiConfig?.fallbackBaseURL && isBrowser ) {
				// catch 5xx error on client side
				// main api is failed so we have to enable fallback setup
				enableBackupAPIsInCookies ();

				const originalRequest = error.config;

				originalRequest.url             = originalRequest.backupApiConfig.fallbackBaseURL;
				originalRequest.backupApiConfig = null;

				return axios ( originalRequest );
			} else {
				return Promise.reject ( error );
			}

		}
	);
};

if ( !called )
	axiosInterceptor ();

const getHeaders = function ( { headers, noBearer } ) {
	const deviceId   = this?.userAuth?.deviceId || getDeviceId ();
	const authToken  = this?.userAuth?.accessToken || getUserData ()?.accessToken;
	const tokenType  = this?.userAuth?.accessTokenType || getUserData ()?.tokenType || "Bearer";
	const deviceType = this?.userAuth?.deviceType || getDeviceType ();
	const appVersion = this?.userAuth?.appVersion || getAppVersion ();

	return {
		...headers,
		"device-type"   : deviceType,
		"app-version"   : appVersion,
		"device-id"     : deviceId,
		"authorization" : noBearer ? authToken : ( authToken ? `${ tokenType } ${ authToken }` : null )
	};
};

const env           = process.env.NODE_ENV;
const serverTimeout = env === "development" ? 30000 : 15000;
const clientTimeout = 30000;

const get = async function ( { headers = {}, url, params, backupApiConfig = {}, timeout }, options = {} ) {
	let _timeout = timeout || ( checkIsBrowser () ? clientTimeout : serverTimeout );

	try {
		const __headers = options?.noHeaders ? {} : getHeaders.bind ( this ) ( { headers, noBearer: options?.noBearer } );

		if ( !__headers.authorization && !options?.noHeaders )
			throw "";

		const isFallbackURLEnabled = this?.isFallbackURLEnabled || checkFallbackURLEnabled ();

		const response = await axios ( {
			url,
			method          : "get",
			timeout         : _timeout,
			accessToken     : this?.userAuth?.accessToken || getUserData ()?.accessToken,
			refreshToken    : this?.userAuth?.refreshToken || getUserData ()?.refreshToken,
			headers         : options?.noHeaders ? headers : __headers,
			params,
			isFallbackURLEnabled,
			backupApiConfig,
			skipInterceptor : options.skipInterceptor || false
			// fromSSR      : !!this?.userAuth?.accessToken
		} );

		if ( !options.emptyResponse && ( !response || !response.data ) )
			throw "data not available";

		return response.data;
	} catch ( error ) {
		/*         const customObj = {
 *             url         : error.config.url,
 *             method      : error.config.method,
 *             params      : error.config.params,
 *             headers     : error.config.headers,
 *             parsedError : _error ( error ),
 *             response    : JSON.stringify ( error.response.data )
 *         };
 *  */
		if ( error !== "data not available" )
			log.error ( { error, url }, "Error making API call." );

		/* log.error ( customObj, "Error making API call." ); */
		throw _error ( error );

	}
};

const post = async function ( { headers = {}, url, transform, data, timeout, config = {}, backupApiConfig = {}, responseType = "json" }, options = {} ) {
	let _timeout = timeout || ( checkIsBrowser () ? clientTimeout : serverTimeout );

	try {
		const __headers = options?.noHeaders ? {} : getHeaders.bind ( this ) ( { headers, noBearer: options?.noBearer } );

		if ( !__headers.authorization && !options?.noHeaders )
			throw "no token created yet";

		const isFallbackURLEnabled = this?.isFallbackURLEnabled || checkFallbackURLEnabled ();

		const response = await axios ( {
			url,
			timeout           : _timeout,
			method            : "post",
			headers           : options?.noHeaders ? headers : __headers,
			transformResponse : transform,
			responseType,
			data,
			accessToken       : this?.userAuth?.accessToken || getUserData ().accessToken,
			refreshToken      : this?.userAuth?.refreshToken || getUserData ().refreshToken,
			backupApiConfig,
			isFallbackURLEnabled,
			// fromSSR           : !!this?.userAuth?.accessToken,
			...config
		} );

		if ( !options?.emptyResponse && ( !response || !response.data ) )
			throw "data not available";

		return response.data;
	} catch ( error ) {
		/*         const customObj = {
 *             url         : error.config.url,
 *             method      : error.config.method,
 *             postData    : error.config.data,
 *             headers     : error.config.headers,
 *             parsedError : _error ( error ),
 *             response    : JSON.stringify ( error.response.data )
 *         };
 *  */

		if ( error !== "data not available" )
			log.error ( { error, url }, "Error making API call." );

		/* log.error ( customObj, "Error making API call." ); */
		throw _error ( error );

	}
};

const put = async function ( { headers = {}, url, transform, data, timeout = 0, config = {} }, options = {} ) {
	try {
		const __headers = options?.noHeaders ? {} : getHeaders.bind ( this ) ( { headers, noBearer: options?.noBearer } );

		if ( !__headers.authorization && !options?.noHeaders )
			throw "no token created yet";

		const response = await axios ( {
			url,
			timeout,
			method            : "put",
			headers           : options?.noHeaders ? headers : __headers,
			transformResponse : transform,
			accessToken       : this?.userAuth?.accessToken,
			refreshToken      : this?.userAuth?.refreshToken,
			data,
			...config
		} );

		if ( !options.emptyResponse && ( !response || !response.data ) )
			throw "data not available";

		return response.data;
	} catch ( error ) {
		if ( error !== "data not available" )
			log.error ( { error, url }, "Error making API call." );

		throw _error ( error );
	}
};

const del = async function ( { headers = {}, url, transform, data, timeout = 0, config = {} }, options = {} ) {
	try {
		const __headers = options?.noHeaders ? {} : getHeaders.bind ( this ) ( { headers, noBearer: options?.noBearer } );

		if ( !__headers.authorization && !options?.noHeaders )
			throw "no token created yet";

		const response = await axios ( {
			url,
			timeout,
			method            : "delete",
			headers           : options?.noHeaders ? headers : __headers,
			transformResponse : transform,
			accessToken       : this?.userAuth?.accessToken,
			refreshToken      : this?.userAuth?.refreshToken,
			data,
			...config
		} );

		if ( !options.emptyResponse && ( !response || !response.data ) )
			throw "data not available";

		return response.data;
	} catch ( error ) {
		if ( error !== "data not available" )
			log.error ( { error, url }, "Error making API call." );

		throw _error ( error );
	}
};

const head = async function ( { url, headers = {} }, options ) {
	try {
		const response = await axios ( {
			url,
			method       : "head",
			refreshToken : this?.userAuth?.refreshToken,
			accessToken  : this?.userAuth?.accessToken,
			headers      : options?.noHeaders ? headers : getHeaders.bind ( this ) ( { headers, noBearer: options?.noBearer } )
		} );

		return response;
	} catch ( error ) {
		log.error ( { error, url }, "Error making API call." );
		throw _error ( error );
	}
};

export default {
	get,
	post,
	put,
	head,
	del
};

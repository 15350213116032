import { AspectRatio, Overlay, Skeleton } from "@mantine/core";
import Link                               from "next/link";

import { formatToRupees, isImageURL } from "_common/utils";
import { getProductPrice }            from "_common/utils/shop";
import Image                          from "_components/atoms/image";
import Typography                     from "_components/atoms/typography";

import ProductItemSkeleton from "./skeleton";
import useStyles           from "./styles";

const component = ( { isLoading, data, productURL } ) => {
	const { classes } = useStyles ();

	if ( isLoading ) {
		return <ProductItemSkeleton />;
	}

	return (
		<Link href = { productURL }
			// onClick = { handleOnClick }
			target    = { "_blank" }
		>
			<div
				className     = { classes.productItemWrapper }
				data-disabled = { data.outOfStock ? data.outOfStock : undefined }
				spacing       = { 4 }
			>
				<div>
					<div className = { classes.imageWrapper }>
						<Skeleton
							className = { classes.imageSkeleton }
							visible   = { !isImageURL ( data?.media?.[0] ) }
						>
							<AspectRatio ratio = { 1 / 1 }>
								<Image
									className = { classes.productImage }
									src       = { data?.media?.[0] }
								/>

								{ data.outOfStock && (
									<Overlay
										color   = "#000"
										opacity = { 0.5 }
										radius  = { "lg" }
										zIndex  = { 1000 }
									>
										<Typography
											color  = { "white" }
											size   = { "xl" }
											title  = { "Out of Stock" }
											weight = { "500" }
										/>
									</Overlay>
								)}
							</AspectRatio>
						</Skeleton>

					</div>

					<ProductDetails
						data = { data }
					/>

				</div>

			</div>
		</Link>

	);
};

function ProductDetails ( { data } ) {

	const { classes, theme } = useStyles ( {}, {
		name: "rooter"
	} );

	const { costPrice, discount, isQwikCilverItem, sellPrice } = getProductPrice ( data );

	return (
		<div className = { classes.productDetailsWrapper }>
			<Typography
				color = "gray"
				mt    = { 8 }
				size  = { "sm" }
				title = { data.brandName }
				tt    = { "capitalize" }
			/>

			<Typography
				lineClamp = { 2 }
				mt        = { 4 }
				size      = { "sm" }
				title     = { data.name }
			/>

			<div
				className = { classes.productDetails }
				spacing   = { 6 }
			>
				{
					discount > 0 ? (
						<div className = { classes.amountWrapper }>
							<Typography
								title  = { formatToRupees ( sellPrice ) }
								weight = { "500" }
							/>

							<Typography
								color = "secondary"
								size  = { "sm" }
								style = { {
									textDecoration: "line-through"
								} }
								title = { formatToRupees ( costPrice ) }
							/>

							<Typography
								color  = { theme.other.paletteNew.tertiarySuccessGreen }
								size   = { "sm" }
								style  = { {
									textWrap: "nowrap"
								} }
								title  = { `${ Math.floor ( discount ) }% off` }
								weight = { "500" }
							/>
						</div>
					) : (
						isQwikCilverItem ? (
							<Typography
								title  = { formatToRupees ( costPrice ) }
								weight = { "500" }
							/>
						) : (
							<Typography
								title  = { formatToRupees ( data?.variation?.[0]?.values?.[0]?.sellPrice ) }
								weight = { "500" }
							/>
						)
					)
				}
			</div>

		</div>
	);
}
export default component;